<template>
    <div class="change_subject_main">
        <div class="container">
            <img class="logo" src="../../assets/img/login/logo_new.png"/>
            <div class="selection">选择身份</div>
            <div class="role_group scrollbar-class">
                <div class="role_item" v-for="(item, i) in companyList" :key="i" :class="item.id == selectedId?'selected':''" @click="changeSelected(item)">
                    <div class="status"></div>
                    <div class="role_info">
                        <div class="line_top">
                            <span class="holder_name">{{item.ue_holder_name}}</span>
<!--                            <img class="over" v-if="!item.in_use" src="../../assets/img/login/overdue_logo.png" alt="">-->
                            <img class="others" v-if="item.in_use && item.vip_type == '10'" src="../../assets/img/login/no_vip_logo.png" alt="">
                            <img class="others" v-if="item.in_use && item.vip_type == '20'" src="../../assets/img/login/vip_logo.png" alt="">
                            <img class="others" v-if="item.in_use && item.vip_type == '30'" src="../../assets/img/login/svip_logo.png" alt="">
                        </div>
                        <div class="line_bottom">
                            <div class="label">
                                <span class="label_name">姓名：</span>
                                <span class="user_name">{{item.user_name}}</span>
                            </div>
                            <div class="label">
                                <span class="label_name">角色：</span>
                                <span>{{item.role == '1'?'管理员':'普通账号'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <myButton
                class="btn"
                @click="handelChange"
                type="primary"
                width="410"
                height="50">
                <span slot="text" style="font-size: 18px;font-weight: bold;">选择身份</span>
            </myButton>
        </div>
    </div>
</template>

<script>
    import myButton from "../../components/myButton/myButton";
    import * as api from "@/api/login";
  export default {
    name: "changeSubject",
    components: {
      myButton
    },
    data() {
      return {
        companyList: [],
        selectedId: '',
      }
    },
    created() {
      api.get_roles().then(res => {
        if(res.data.result_code == 0) {
          this.companyList = res.data.data.roles;
          this.selectedId = res.data.data.selected_id;
        }
      })
    },
    methods: {
      changeSelected(item) {
        this.selectedId = item.id
      },
      handelChange() {
        if(!this.selectedId) {
          this.$message({
            type: 'warning',
            message: '请选择一个身份'
          })
        } else {
            let params = {
              id: this.selectedId
            }
            api.select_role(params).then(res => {
              if(res.data.result_code == 0) {
                this.$message({
                  type: 'success',
                  message: '请求成功'
                })
                localStorage.setItem("ajt_token", res.data.data.token);
                this.$router.push('/box/dataView')
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.change_subject_main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: url("../../assets/img/login/logo_bg.png");
    background-size: cover;
    .container {
        width: 523px;
        height: 624px;
        background-color: #ffffff;
        box-shadow: 0px 3px 15px 0px
        rgba(153, 153, 153, 0.1);
        border-radius: 6px;
        text-align: center;
        .logo {
            width: 138px;
            height: 138px;
            margin-top: 45px;
        }
        .selection {
            margin-top: 26px;
            font-size: 18px;
            color: #1f81f8;
            margin-bottom: 16px;
        }
        .scrollbar-class::-webkit-scrollbar-track {
            background-color: #fff;
        }
        .role_group {
            display: flex;
            flex-direction: column;
            height: 276px;
            overflow-y: scroll;
            width: calc(100% - 114px);
            margin: 0 auto;
            .disabled {
                cursor: not-allowed !important;
                .status {
                    background-color: #faf9f9;
                }
                .line_top {
                    color: #999999 !important;
                }
                .label {
                    color: #999999 !important;
                }
            }
            .role_item.selected {
                border: solid 1px #1f81f8;
                .status {
                    border: solid 1px #1f81f8;
                    &:after {
                        display: inline-block;
                        content: '';
                        width: 10px;
                        height: 10px;
                        background-color: #1f81f8;
                        border-radius: 5px;
                        position: relative;
                        top: 3px;
                    }
                }
            }
            .role_item {
                cursor: pointer;
                padding: 17px;
                background-color: #ffffff;
                border-radius: 6px;
                border: solid 1px #f1f1f1;
                margin-top: 15px;
                display: flex;
                align-items: center;
                .status {
                    width: 17px;
                    height: 17px;
                    border-radius: 10px;
                    border: solid 1px #f1f1f1;
                    margin-right: 13px;
                }
                .role_info {
                    .line_top {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        color: #333333;
                        font-weight: bold;
                        .over {
                            width: 58px;
                            height: 16px;
                            margin-left: 10px;
                        }
                        .others {
                            width: 73px;
                            height: 16px;
                            margin-left: 10px;
                        }
                        .holder_name {
                            text-overflow: ellipsis;
                            max-width: 250px;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                    .line_bottom {
                        display: flex;
                        margin-top: 13px;
                        .label {
                            font-size: 12px;
                            color: #333333;
                            margin-right: 22px;
                            .label_name {
                                color: #999999;
                            }
                            .user_name {
                                text-overflow: ellipsis;
                                max-width: 250px;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
        .btn {
            margin-top: 25px;
        }
    }
}
</style>
